import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Management from "../assets/Registry/management-view.svg";
import StreamlinedApplication from "../assets/registry.svg";
import Integration from "../assets/Registry/trustflight-integration.svg";
import TechCard from "../components/TechLog/techCard";
import Started from "../components/Layout/started";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import pageStyles from "./page.module.less";
import registryStyles from "./registry.module.less";
import styles from "./taskcards.module.less";
import SEO from "../components/SEO";
import jarScreenshot from "../assets/Registry/pilot-validation.jpg";
import jarStorybook from "../assets/Registry/storybook.jpg";
import hourRegistration from "../assets/Registry/24-hour-registration.svg";
import registrationIncrease from "../assets/Registry/registration-increase.svg";
import simplifiedProcess from "../assets/Registry/simplified-process.svg";

import ScrollAnimation from "react-animate-on-scroll";

const StreamlinedCard = {
  img: StreamlinedApplication,
  title: "Streamlined Application Process",
  text:
    "Our online registration is simple and clear. Each step is clearly guided giving you confidence in the process and your application will be processed quickly and efficiently.",
};

const ManagementCard = {
  img: Management,
  title: "Single Management View",
  text:
    "A simplified view of the status of all of your applications as well as simple controls for managing your registered aircraft.",
};

const IntegrationCard = {
  img: Integration,
  title: "TrustFlight Integration",
  text:
    "Having your aircraft registered with us opens the door to the whole TrustFlight suite. Take a look at our full range of products to see how we can take care of your aviation needs.",
};

const hourRegistrationCard = {
  img: hourRegistration,
  text: "< 24 hour registration enabled",
};

const registrationIncreaseCard = {
  img: registrationIncrease,
  text: ">100% increase in Aircraft Registrations",
};

const simplifiedProcessCard = {
  img: simplifiedProcess,
  text: "Simplified process",
};

const cardsArrayBlockchain = [StreamlinedCard, ManagementCard, IntegrationCard];
const cardsArrayRegistry = [
  simplifiedProcessCard,
  hourRegistrationCard,
  registrationIncreaseCard,
];

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
  padding-bottom: 60px;
`;

export default (props) => {
  const cardsBlockchain = cardsArrayBlockchain.map((card, key) => (
    <Col md={4} className={`${styles.techCardCol} ${pageStyles.coliPad}`}>
      <ScrollAnimation
        animateOnce
        animateIn={
          key === 0 ? "fadeInLeft" : key === 1 ? "fadeInUp" : "fadeInRight"
        }
        offset={0}
      >
        <TechCard
          key={card.title}
          img={card.img}
          title={card.title}
          text={card.text}
        />
      </ScrollAnimation>
    </Col>
  ));

  const cardsRegistry = cardsArrayRegistry.map((card, key) => (
    <Col md={4} className={`${styles.techCardCol} ${pageStyles.coliPad}`}>
      <ScrollAnimation
        animateOnce
        animateIn={
          key === 0 ? "fadeInLeft" : key === 1 ? "fadeInUp" : "fadeInRight"
        }
        offset={0}
      >
        <TechCard
          noShadow
          key={card.title}
          img={card.img}
          text={card.text}
          fixedHeight={100}
        />
      </ScrollAnimation>
    </Col>
  ));

  const { mainImage } = props.data;
  return (
    <Layout>
      <SEO
        title="Aircraft Registry"
        description="Introducing the world’s first blockchain-based registry."
      />
      <PageHeader
        header="Aircraft Registry"
        subHeader="Introducing the world’s first blockchain-based registry."
        linkText="Enquire now"
        link="/contact?question"
        bigHeader
        bigImage={mainImage.childImageSharp.fluid}
      />
      <GradientSection>
        <Container>
          <Row className={`${pageStyles.pageParentRow} ${styles.titleRow}`}>
            <Col md={12}>
              <CenteredDiv>
                <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
                  <h3 className={styles.title}>
                    Unique blockchain-based registry
                  </h3>
                </ScrollAnimation>
              </CenteredDiv>
            </Col>
            <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }}>
              <CenteredDiv>
                <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                  <h4 className={styles.subTitle}>
                    Your application is tracked and stored against our
                    world-first blockchain-based registry system giving you
                    complete trust in your registration and opening the door to
                    a world of future innovations with the cutting edge
                    technology.
                  </h4>
                </ScrollAnimation>
              </CenteredDiv>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col lg={10} md={12}>
              <Row>{cardsBlockchain}</Row>
            </Col>
          </Row>
        </Container>
      </GradientSection>

      <Container>
        <Row className={`${registryStyles.parentRowSpaced}`}>
          <Col md={{ span: 6, offset: 3 }}>
            <h3 className={styles.title}>
              TrustFlight has developed the technology to simplify Aircraft Registration
            </h3>
          </Col>
        </Row>
        <Row className={`${registryStyles.titleRowSpaced}`}>
          <Col
            lg={{ span: 5, offset: 1 }}
            md={6}
            className={`${pageStyles.coliPad}`}
          >
            <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
              <img src={jarScreenshot} className={registryStyles.screenshot} />
            </ScrollAnimation>
          </Col>
          <Col lg={{ span: 5 }} md={6} className={pageStyles.coliPad}>
            <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <h4 className={registryStyles.screenshotCopy}>
                The aircraft registration process is complex and often takes a
                long time. This has been dramatically simplified using
                TrustFlight’s technology, to create a simple, efficient aircraft
                registration service the industry needs.
              </h4>
              <h4 className={registryStyles.screenshotCopy}>
                TrustFlight has used technology to enhance the aircraft registration process, using automation to reduce time and errors in the
                application process. This use of technology has improved user
                experience and reduced processing time by a significant factor.
              </h4>
              <p
                className={`${registryStyles.screenshotCopy} ${registryStyles.quote}`}
              >
                “The online application process provides an efficient way to
                move aircraft.”
              </p>
              <p
                className={`${registryStyles.screenshotCopy} ${registryStyles.quote} ${registryStyles.quoteName} `}
              >
                KEVIN PERRYMAN
              </p>
              <p
                className={`${registryStyles.screenshotCopy} ${registryStyles.quote} ${registryStyles.quoteName}  ${registryStyles.quoteNameCompany}`}
              >
                CIAS
              </p>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${registryStyles.pageRowSpaced}`}
        >
          <Col lg={10} md={12}>
            <h3 className={styles.title}>What could be achieved?</h3>
            <Row>{cardsRegistry}</Row>
          </Col>
        </Row>

        <Row className={`${registryStyles.pageRowSpaced}`}>
          <Col
            lg={{ span: 6, offset: 0 }}
            md={6}
            className={`${pageStyles.coliPad}`}
          >
            <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
              <img
                height="430"
                src={jarStorybook}
                className={registryStyles.jarStorybook}
              />
            </ScrollAnimation>
          </Col>
          <Col md={{ span: 5 }} lg={4} className={pageStyles.coliPad}>
            <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <h3 className={`${registryStyles.titleLeft}`}>
                Instant Validation
              </h3>
              <h4 className={registryStyles.screenshotCopy}>
                TrustFlight's blockchain technology enables the instant
                validation of certificates and documents using storybook
                verification. This technology has enabled easy inspection of
                documents by regulators globally, another global first in
                paperless operations.
              </h4>
              <p
                className={`${registryStyles.screenshotCopy} ${registryStyles.quote}`}
              >
                “The effect of the Aircraft Registration platform is outstanding
                and the tools built enable the industry to simply transfer
                aircraft onto and off the registry.”
              </p>
              <p
                className={`${registryStyles.screenshotCopy} ${registryStyles.quote} ${registryStyles.quoteName} `}
              >
                SEBASTIAN LAWSON
              </p>
              <p
                className={`${registryStyles.screenshotCopy} ${registryStyles.quote} ${registryStyles.quoteName}  ${registryStyles.quoteNameCompany}`}
              >
                General Manager
              </p>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
      <Container>
        <Started buttonText="Ready to register?" />
      </Container>
      <AlsoInterestedIn page="registry" />
    </Layout>
  );
};

export const query = graphql`
  query RegistryQuery {
    mainImage: file(relativePath: { eq: "Registry/registry-featured@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
